<template>
  <div>
    <p class="text-xs pl-3 text-left block mb-1">Curriculum Vitae</p>
    <div class="modal-body-drag-area" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <div v-if="!isUploading" class="w-full">
        <input type="file" name="file-input" id="assetsFieldHandle" @change="onChange" ref="file" accept="application/pdf,image/png" />
        <div class="upload-icon flex flex-col justify-center items-center">
          <upload width="48" height="48" color="#3b82f6" />
          <div class="upload-icon-text">Drag & Drop File</div>
          <div>Or</div>
        </div>
        <label for="assetsFieldHandle" class="block cursor-pointer">
          <span v-if="!isReadyToSend">Select File</span>
          <span v-else>Change File</span>
        </label>
      </div>
      <div class="text-left flex justify-center items-center w-full flex-col mt-2">
        <div class="flex gap-2 items-center mb-2 mdmax:w-full" v-for="(file, fileIndex) in seletedFile" :key="'file-' + fileIndex">
          <div class="text-sm text-neutral-400 flex gap-2 items-center justify-between bg-grey py-2 px-3 rounded relative">
            <Attach color="#ADADAD" />
            <span class="overflow-ellipsis w-80 whitespace-nowrap overflow-hidden mdmax:w-auto mdmax:whitespace-normal mdmax:overflow-auto mdmax:break-all">{{ file.name }}</span>
            <span class="text-sm text-neutral-400 inline-block w-20 text-right mdmax:whitespace-nowrap">({{ bytesToSize(file.size) }}) </span>
            <span @click="remove(fileIndex)" class="inline-block absolute -right-2 -top-2 cursor-pointer bg-white rounded-full border-system-error"
              ><Close color="system-error" width="16" height="16"
            /></span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="upload-submit">
      <button :disabled="!isReadyToSend || isUploading" :class="{ disabled: !isReadyToSend }" @click="submit">
        <template v-if="!isUploading">Upload</template>
        <LoadingDot v-else />
      </button>
    </div> -->
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import LoadingDot from '@/components/illustration/LoadingDot'
export default {
  components: {
    // Upload,
    // LoadingDot,
    Attach: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Attach'),
    Upload: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Upload'),
    Close: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Close')
  },
  props: ['classId'],
  data: () => ({
    seletedFile: {},
    error: null,
    isReadyToSend: false,
    isUploading: false,
    progressPercentage: [],
    progressReal: [],
    maxFiles: 5,
    maxFileSize: 800 * 1024 * 1024
  }),
  watch: {
    seletedFile(val) {
      if (val.length < 1) {
        this.isReadyToSend = false
      }
    }
  },
  methods: {
    ...mapActions('gallery', ['UPLOAD_FILE']),
    close(event) {
      this.$emit('close')
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    },
    onChange() {
      this.seletedFile = Array.from(this.$refs.file.files)
      this.isReadyToSend = true
      this.$emit('select', this.seletedFile)
    },
    closeAndRefetch() {
      this.$emit('closeAndRefetch')
    },
    remove(i) {
      this.seletedFile.splice(i, 1)
      this.error = null
      this.checkError()
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('uploading')) {
        event.currentTarget.classList.add('uploading')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('uploading')
    },
    drop(event) {
      this.error = null
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('uploading')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-body {
  position: relative;
  &-drag {
    &-area {
      border: 2px dashed #ccc;
      border-radius: 6px;
      padding: 20px;
      display: flex;
      min-height: 200px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: all 200ms linear;
      @media screen and (max-width: 767px) {
        padding: 12px;
      }
      input {
        position: absolute;
        opacity: 0;
        overflow: hidden;
        height: 1px;
        width: 1px;
      }
      label {
        cursor: pointer;
        margin-top: 15px;
        span {
          background-color: #3b82f6;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 3px;
          padding: 4px 16px;
          font-size: 12px;
          transition: all 100ms linear;
          display: inline-block;
          margin-bottom: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .upload-icon {
        &-text {
          margin-bottom: 5px;
        }
      }
    }
  }
}
.uploading {
  background-color: #eee;
}
.upload-submit {
  text-align: center;
  margin-top: 30px;
  button {
    background-color: #3b82f6;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    padding: 8px 40px;
    margin: 0;
    transition: all 100ms linear;
    width: 100%;
    &:hover {
      opacity: 0.8;
    }
    &.disabled {
      background-color: #ccc;
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.error {
  color: red;
  margin: 10px 0px;
  min-height: 20px;
}
.upload-files {
  &-name {
    display: block;
    color: #999;
  }
  &-size {
    font-size: 12px;
    color: #999;
    display: inline-block;
    margin-left: 5px;
  }
}
.notes {
  color: #999;
  font-size: 12px;
}
.progress {
  background-color: #3b82f6;
}
.rounded-lg {
  border-radius: 8px !important;
}

$progress-bar-stroke-width: 1.8;
$progress-bar-size: 36px;

.bar svg {
  height: $progress-bar-size;
  transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #eee;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #eee;
  stroke: #3b82f6;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 100ms ease-in-out;
}
.border-system-error {
  border-width: 1px !important;
}
</style>
